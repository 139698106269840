import React from "react"
import { graphql, Link, StaticQuery } from "gatsby"
import Layout from "../../../components/layout"
import SEO from "../../../components/seo"
import css from "../style/singlepage.module.scss"
import Doc from "../../../components/Doc"
import { OutboundLink } from "gatsby-plugin-google-analytics"
import ExhibitorList from "../../../components/ExhibitorList"

const IndexPage = data => {
  return (
    <Layout mode={`about`} lang={`ja`}>
      <SEO
        ogImagePath={`/assets/img/ogp-exhibition.jpg`}
        title={`TRANS BOOKS DOWNLOADs 期間限定リアルショップオープン！`}
        description={`2020年12月11日（金）から20日（日）まで、武蔵小山にあるsame galleryにて「TRANS BOOKS DOWNLOADs EXHIBITION」を開催いたします。`}
        lang={`ja`}
      />
      <div className={css.wrap}>
        <div className={css.flexSticky}>
          <div className={css.flexSticky__left}>
            <div className={css.flexSticky__left__heading}>
              <div className={`${css.logoBox} fixedRatioBox is-1x1 border`}>
                <video autoPlay={true} muted={true} width="250">
                  <source
                    src="/downloads/assets/images/logo/exhibition2.mp4"
                    type="video/mp4"
                  />
                  動画を再生するにはvideoタグをサポートしたブラウザが必要です。
                </video>
              </div>
            </div>
          </div>
          <div className={css.flexSticky__right}>
            <Doc>
              <p>
                <div
                  style={{
                    border: "2px solid #0000ff",
                    padding: "10px",
                    color: "#0000ff",
                    textAlign: "center",
                  }}
                >
                  このイベントは終了しました。
                </div>
              </p>
              <h3 style={{ marginTop: 0 }}>期間限定リアルショップオープン！</h3>
              <p>
                このたび、TRANS BOOKS
                運営委員会では、2020年12月11日（金）-13日（日）と18日（金）-20日（日）の6日間、武蔵小山にあるsame
                galleryにて「TRANS BOOKS DOWNLOADs
                EXHIBITION」を開催いたします。 <br />
                <small>※金と土日でオープン時間が異なるのでご注意下さい。</small>
              </p>
              <p>
                「TRANS BOOKS DOWNLOADs
                EXHIBITION」は現在オンラインにてオープンしている、
                データ書店「TRANS BOOKS
                DOWNLOADs」のリアル店舗として企画された展覧会になります。
              </p>
              <p>
                「TRANS BOOKS
                DOWNLOADs」は参加作家が手掛けた多様な本の「データ」をオンラインショップにて販売する「データ書店」です。2020年5月からショップを立ち上げ、様々なデータ書籍をダウンロード販売して参りました。当書店では、手元のパソコンや実空間に「ダウンロード」することで様々な読書体験をお楽しみいただける本の「データ」を取り扱っています。
              </p>
              <p>
                今回はリアル店舗として、出力された本を会場にて展示。出力された本たちを手にとって楽しめる場を提供します。ぜひこの機会に「TRANS
                BOOKS
                DOWNLOADs」で販売されているデータ《本》の魅力を感じていただけば幸いです。
              </p>
              <p>
                <small>
                  ※今回の展覧会で展示される本は「TRANS BOOKS
                  DOWNLOADs」のウェブサイトからオンラインで購入していただく形になります。展覧会会場で出力された本の販売はございません。
                </small>
              </p>
              <h3 style={{ marginTop: `80px` }}>基本情報</h3>
              <dl className={`tiny-dl`}>
                <dt>イベントタイトル</dt>
                <dd>TRANS BOOKS DOWNLOADs (トランスブックスダウンローズ)</dd>
                <dt>日程</dt>
                <dd>
                  2020年12月11日(金), 12日(土), 13日(日), 18日(金), 19日(土)
                  ,20日(日)
                </dd>
                <dt>オープン時間</dt>
                <dd>
                  金曜日：16:00～21:00 <br />
                  土曜日、日曜日：12:00～19:00
                </dd>
                <dt>入場料</dt>
                <dd>無料</dd>
                <dt>会場</dt>
                <dd>
                  <OutboundLink
                    href={`https://samegallery.com/`}
                    target={`_blank`}
                  >
                    same gallery
                  </OutboundLink>
                  （武蔵小山） <br />
                  <OutboundLink
                    href={`https://goo.gl/maps/JC1cKZQs7uc8EsSZ8`}
                    target={`_blank`}
                  >
                    〒142-0063 東京都品川区荏原４丁目６−７
                  </OutboundLink>
                </dd>
                <dt>公式サイト</dt>
                <dd>https://transbooks.center/</dd>
                <dt>公式SNS</dt>
                <dd>
                  <OutboundLink
                    href={`https://www.facebook.com/transbookstrans/`}
                    target={`_blank`}
                  >
                    Facebook
                  </OutboundLink>{" "}
                  /{" "}
                  <OutboundLink
                    href={`https://twitter.com/transbookstrans`}
                    target={`_blank`}
                  >
                    Twitter
                  </OutboundLink>{" "}
                  /{" "}
                  <OutboundLink
                    href={`https://www.instagram.com/transbooks/`}
                    target={`_blank`}
                  >
                    Instagram
                  </OutboundLink>
                </dd>
                <dt>主催 </dt>
                <dd>
                  TRANS BOOKS 運営委員会 <br />
                  (飯沢未央 / 畑 ユリエ / 萩原俊矢 / 齋藤あきこ)
                </dd>
                <dt>協力</dt>
                <dd>same gallery</dd>
              </dl>
            </Doc>

            <Doc>
              <h3 style={{ marginTop: `80px` }}>参加作家</h3>

              <ExhibitorList lang={`ja`} type={`sameGallery`} />
            </Doc>

            <Doc>
              <h3 style={{ marginTop: `80px` }}>開催に寄せて</h3>

              <p>
                「TRANS
                BOOKS」は、電子も非電子もそれ以外もメディアを問わず、どんな本でも販売するブックフェアとして、2017年から開催しています。これまで毎年秋にに開催し、数々の多彩な書籍を販売してきました。
              </p>

              <p>
                《本》は人と情報の橋渡しし、時代とともに形をかえて、広がり続けるものです。このような時代性を表す《本》だからこそできる表現や、コミュニケーションをもっと探ってみたいと思い始めたのが「TRANS
                BOOKS」です。
              </p>

              <p>
                私たちは《本》を表現を懐深く受け入れるプラットフォームであると考え、本や本を取り巻く体験の多様性、可能性や面白さを、楽しみながらふれてもらう場を目指し、活動を続けてきました。
              </p>

              <p>
                2020年に入り、私たちを取り巻く環境は大きく変化しました。それに伴い、本を始めとするメディアと人々の関係も、変わりつつあることを感じています。これまで当たり前のようにリアルで見たり触れていたものを、オンラインやデジタルに置き換えるだけではない関係性が、これから作り出されて行くのではないでしょうか。
              </p>

              <p>
                そんな状況の中、どのような《本》の可能性があるか。運営メンバーで、今ならではのメディアと自分たちの関係についてディスカッションし、導き出されたテーマが「Download」でした。「Download」はネットワーク上にあるデータを、自分のパソコンやスマートフォンへ、そしてオンライン空間から実生活へ転送することです。
              </p>

              <p>
                私たちは「Download」する行為を、オンラインと自分たちの世界をつなぐ入り口として捉え、「TRANS
                BOOKS
                DOWNLOADs」をオープンいたしました。当書店では、今だからこそ楽しめる、本と本に関わる多様な体験を提供することを目指しています。
              </p>

              <p>
                この度はリアル書店として「TRANS BOOKS DOWNLOADs
                EXHIBITION」を開催。出力された本たちをぜひ手にとって楽しんでほしいという思いから企画いたしました。
              </p>

              <p>
                会場では「TRANS BOOKS
                DOWNLOADs」で販売しているデータから出力された本たちが展示され、来場者はそれらの本を手に取って読みつつ、興味のある本のデータを購入することができます。
              </p>

              <p>
                ぜひこの機会に「TRANS BOOKS
                DOWNLOADs」で販売されているデータ《本》の魅力を感じていただけば幸いです。
              </p>

              <p>TRANS BOOKS 運営委員会</p>
              <hr />
              <h3>連絡先 & お問い合わせ</h3>
              <p>transbooks.info [at-mark] gmail.com</p>
            </Doc>
          </div>
        </div>
      </div>
    </Layout>
  )
}
export default prop => {
  return (
    <StaticQuery
      query={graphql`
        query {
          allMarkdownRemark {
            edges {
              node {
                frontmatter {
                  artistName
                  alphabetical
                  artistTitle
                }
                fields {
                  slug
                  langKey
                }
                id
              }
            }
          }
        }
      `}
      render={data => <IndexPage site={data}>{prop.children}</IndexPage>}
    />
  )
}
